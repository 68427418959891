import styled, { css, keyframes } from 'styled-components';

import Colors from '../../utils/Colors';
export const HideElement = keyframes`
  to {
    visibility: hidden;
  }
`;
export const BackgroundOpacity = styled.div<{ showModal: boolean }>`
  position: fixed;
  z-index: 500;
  left: 0;
  top: 0;
  opacity: ${props => (props.showModal ? 1 : 0)};
  width: 100%;
  height: 100%;
  overflow: auto;
  color: ${Colors.black};
  background-color: rgba(216, 216, 216, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.15s ease-in;
  ${props =>
    !props.showModal &&
    css`
      animation: ${HideElement} 0.5s ease-in forwards;
    `}
`;

const TranlateFromBottom = keyframes`
  from {
    transform: translateY(120%);
  }
  to {
    transform: translateY(0);
  }
`;

const TranslateToBottom = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(120%);
  }
`;

export const ModalContainer = styled.div<{ mobileBorderRadius?: string; autoHeight?: boolean; isMini?: boolean; showModal?: boolean; noAnimation?: boolean }>`
  display: ${props => (props.showModal ? 'flex' : 'none')};
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: ${Colors.white};
  border-radius: 18px;
  min-width: 500px;
  max-height: ${props => (props.isMini ? 'unset' : '80vh')};
  min-height: ${props => (props.isMini ? 'unset' : '80vh')};
  height: ${props => (props.isMini ? 'unset' : '80vh')};
  box-shadow: 0 3px 25px 0 rgba(0, 0, 0, 0.1);
  ${props =>
    props.autoHeight &&
    css`
      height: auto;
      min-height: auto;
      max-height: auto;
    `}
  @media (max-width: 768px) {
    width: ${props => (props.isMini ? '90%' : '100%')};
    min-width: ${props => (props.isMini ? '90%' : '100%')};
    max-height: 100%;
    border-radius: ${props => (props.mobileBorderRadius ? props.mobileBorderRadius : props.isMini ? '20px' : '0')};
    height: ${props => (props.isMini ? 'unset' : '100%')};
  }

  animation: ${props => {
      if (props.noAnimation) {
        return 'none';
      }
      return props.showModal ? TranlateFromBottom : TranslateToBottom;
    }}
    0.3s ease-in-out forwards;
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px 12px 20px;
  width: 100%;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  font-size: 16px;
  font-weight: 800;
  background-color: ${Colors.defaultGrayBackground};
  border-bottom: 1px solid ${Colors.divider};
`;
export const CloseModal = styled.div`
  width: 25px;
  height: 25px;
  background-color: ${Colors.black};
  border-radius: 50%;
  padding: 5px;
  border: none;
  &:hover {
    cursor: pointer;
  }
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ModalDivider = styled.div<{ show?: boolean }>`
  height: 1px;
  background-color: rgb(231, 231, 231);
  width: 100%;
  opacity: ${props => (props.show ? 1 : 0)};
`;
export const ModalBody = styled.div<{ minHeight?: number; maxWidth?: number; padding?: string; maxHeight?: number; minWidth?: number; compact?: boolean; isMini?: boolean }>`
  display: flex;
  flex: ${props => (props.isMini ? 1 : 'unset')};
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
  /* padding: ${props => (props.padding ? props.padding : props?.compact ? '0 40px' : '0')}; */
  width: 100%;
  max-height: ${maxHeight => (maxHeight ? `${maxHeight}vh` : '75vh')};
  /* overflow-y: scroll; */
  /* min-height: ${props => (props.minHeight ? `${props.minHeight}px` : 'auto')}; */
  transition: all 0.3s ease-in-out;
  height: ${props => (props.isMini ? 'unset' : '100%')};
  min-width: ${props => (props.minWidth || props?.compact ? 650 : 900)}px;
  max-width: ${props => (props.maxWidth || props?.compact ? 650 : 900)}px;
  @media (max-width: 768px) {
    min-width: 100%;
    max-width: 100%;
    min-height: unset;
    height: 100%;
  }
`;
export const ModalFooter = styled.div<{ bgColor?: string }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 12px 20px;
  width: 100%;
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
  background-color: ${props => (props.bgColor ? props.bgColor : '#f1f1f1')};
  @media (max-width: 768px) {
    justify-self: flex-end;
  }
`;

export const Divider = styled.div<{ marginBottom?: number; marginTop?: number }>`
  min-height: 1px;
  max-height: 1px;
  height: 1px;
  background-color: ${Colors.divider};
  ${props =>
    props.marginBottom &&
    css`
      margin-bottom: ${props.marginBottom}px;
    `}
  ${props =>
    props.marginTop &&
    css`
      margin-top: ${props.marginTop}px;
    `}
`;

export const ModalWrapper = styled.div<{ compact?: boolean; isMini?: boolean }>`
  display: flex;
  flex: ${props => (props.isMini ? 1 : '1 0 0')};
  box-sizing: border-box;
  /* height: 70vh; */
  /* max-height: 650px; */
  align-self: stretch;
  overflow-y: hidden;
  ${props =>
    !props.compact &&
    css`
      height: 100%;
      width: 100%;
    `}
`;

export const FormButtonsContainer = styled.div<{ children: any; justifyContent?: string; flexDirection?: string }>`
  display: flex;
  align-items: center;
  justify-content: ${props => props.justifyContent || 'center'};
  padding: 20px;
  padding-left: 0;
  flex-direction: ${props => props.flexDirection || 'row'};
  width: 100%;
  gap: 16px;
  border-top: 1px solid #e8e8e8;
`;

export const FormError = styled.span<{ error?: string }>`
  font-size: 12px;
  font-weight: 500;
  margin-top: 4px;
  color: ${Colors.error};
`;

export const InputWrapper = styled.div<{ isCompact?: boolean; padding?: string; gap?: string; isMini?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: ${props => (props.isMini ? 1 : 'unset')};
  width: 100%;
  overflow: scroll;
  padding: ${props => (props.isCompact ? '20px 60px' : '20px 60px 20px 20px')};
  padding: ${props => (props.padding || props.isCompact ? '20px 60px' : '20px 60px 20px 20px')};
  gap: ${props => props.gap || '20px'};
  @media (max-width: 768px) {
    padding: 20px;
  }
`;

export const InputGroup = styled.div<{ width?: string; flex1?: boolean; direction?: 'row' | 'column'; maxWidth?: string }>`
  display: flex;
  max-width: ${props => props.maxWidth || '100%'};
  flex-direction: ${props => props.direction || 'column'};
  width: ${props => props.width || '100%'};
  ${props =>
    props.flex1 &&
    css`
      flex: 1;
    `}
`;

export const FormLabel = styled.label<{
  error?: string | boolean;
  marginBottom?: number;
  bold?: boolean;
  color?: string;
  clickable?: boolean;
  wrap?: boolean;
  marginLeft?: string;
  width?: string;
  normalWhiteSpace?: boolean;
  fontWeight?: number;
  marginTop?: string;
  mobileOverflow?: boolean;
}>`
  white-space: ${props => (props.wrap ? 'wrap' : 'nowrap')};
  white-space: ${props => (props.normalWhiteSpace ? 'normal' : 'nowrap')};
  margin-bottom: 8px;
  font-size: 12px;
  color: ${props => (props.error ? Colors.error : props.color || Colors.black)};
  width: ${props => (props.width ? props.width : 'auto')};
  ${props =>
    props.clickable &&
    css`
      cursor: pointer;
    `}
  ${props =>
    props.bold &&
    css`
      font-weight: 800;
      font-size: 13px;
      color: black;
      margin-bottom: 12px;
    `}

  ${props =>
    props.marginBottom !== undefined &&
    css`
      margin-bottom: ${props.marginBottom}px;
    `}
  ${props =>
    props.marginLeft &&
    css`
      margin-left: ${props.marginLeft};
    `};
  margin-top: ${props => props.marginTop || '0'};
  @media (max-width: 768px) {
    ${props =>
      props.mobileOverflow &&
      css`
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      `}
  }
`;
