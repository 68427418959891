const { version } = require('../../package.json');
const FIREBASE = require('./config-fb-dev');

module.exports = {
  ENV: 'development',
  FIREBASE,
  API_URL: 'https://collar-api.dev.collar.pet/graphql',
  SUB_API_URL: 'wss://collar-api.dev.collar.pet/graphql',
  CLIENT_ID: `APP-${version}`,
  STRIPE_PUB_KEY: 'pk_test_51HUs4kKwlInnaEqs7LjlGDuFZyS62CJvdgJNaxwuedat7SjGBx8kKTECk4hqpLcn5req60a4bGEF4ZL9MMXExdIA00oWtbfsJV',
  RECAPTCHA_SITE_KEY: '6LcOWQMhAAAAALNGNmUAfeCSHFu9GsS5GyIbKXAC',
  APP_URL: 'https://app.collarapp.uk',
  APP_ALIAS_URLS: ['https://book.collarapp.uk', 'https://book.collar.pet', 'https://app.collar.pet'],
  APP_IOS_URL: 'https://apps.apple.com/app/Collar/id1535869072',
  APP_ANDROID_URL: 'https://play.google.com/store/apps/details?id=com.layers.collar',
  BUGSNAG_KEY: '52d38b0f7b077928b555d6bbc2090313'
};
