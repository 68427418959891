import { gql } from '@apollo/client';

const MESSAGE_READ_STATUS_FRAGMENT = gql`
  fragment MessageReadStatusFragment on MessageReadStatus {
    id
    status
    last_read_message
    last_read_timestamp
    AppUser {
      id
    }
  }
`;

const BRANCH_FORM_FRAGMENT = gql`
  fragment BranchFormFragment on Form {
    id
    name
    Questions(order: "sort_index") {
      id
      title
      type
      sort_index
      QuestionCategories(limit: 1) {
        id
      }
      defaultAnswers {
        PetRecordId
        answer
        AnswerOptionId
      }
      updates_field
      ask_per_pet
      get_answers_from
      AnswerOptions {
        id
        title
      }
    }
  }
`;
export const RefreshToken = gql`
  mutation refreshToken($refreshToken: String!) {
    refreshToken(RefreshTokenInput: { refreshToken: $refreshToken }) {
      token
      refreshToken
    }
  }
`;

export const CheckEmailTaken = gql`
  mutation checkEmailAppUser($email: String!) {
    checkEmailAppUser(AppUserEmailInput: { email: $email }) {
      exists
    }
  }
`;

export const SignupUser = gql`
  mutation createAppUser($name: String!, $postcode: String!, $email: String!, $consent_time: Date!, $country_id: Int!) {
    createAppUser(AppUser: { email: $email, name: $name, postcode: $postcode, data_consent: true, comms_consent: false, consent_time: $consent_time, country_id: $country_id }) {
      message
    }
  }
`;

export const SendVerificationEmail = gql`
  mutation sendVerificationEmail($email: String!) {
    sendVerificationEmail(AppUserEmailInput: { email: $email }) {
      message
    }
  }
`;

export const VerifyUser = gql`
  mutation verifyAppUser($code: String!, $email: String!) {
    verifyAppUser(AppUserVerifyInput: { email: $email, code: $code }) {
      token
      firebaseToken
      refreshToken
    }
  }
`;

export const GetAllPets = gql`
  query getProfile {
    getProfile {
      id
      email
      name
      Pets(order: "name") {
        id
        name
        profile_pic
        birthdate
        type
        gender
        PetRecord {
          id
        }
        BreedId
      }
      ChappedPets(order: "name") {
        id
        name
        profile_pic
        birthdate
        type
        gender
        PetRecord {
          id
        }
        AppUser {
          id
          profile_pic
        }
        BreedId
      }
    }
  }
`;

const GetPetRecordPayload = `{
  id, vet_name, microchip_number, microchip_provider, neutred, passport_number, shape, allergies, chronic_conditions, disabilities, measurements
  Branch { id, name, email, address, contact_number, opening_hours, latitude, longitude, photos, colors },
  Branches { id, name, email, address, contact_number, opening_hours, latitude, longitude, photos, colors },
  Meals { id, time, quantity, unit, Branch { id, name } },
  SuppRecords { id, time, quantity, type, adherences_allowed, start_date, Product { id, name, weight, unit, Branch {id, name}, ProductKinds { id, name } } },
  VaccRecords { id, name, dates, nextdate, color, VaccRecordType { id, name } }
  MedConds(order: "reverse:updatedAt") {
    id, dates, name, description, createdAt, 
    Notes(order: "reverse:timestamp") {
      id, timestamp, body, BusUser {id, name} 
    },
    SuppRecords(order: "reverse:updatedAt") {
      id, quantity, time, adherences_allowed,
      Product {
        id, name, weight, unit, ProductKinds { id, name } 
      }
    },
    Adherences {
      id
      SuppRecord(order: "reverse:updatedAt") {
        id, quantity, time, adherences_allowed,
        Product {
          id, name, weight, unit, ProductKinds { id, name } 
        }
      },
    }
    VaccRecords { id, name, dates, nextdate, color, VaccRecordType { id, name } }
    Branch { id, name } 
  }
  BranchCategories { id, name }
}`;
const GetPetPayload = `{
  id, name, profile_pic, gender, PetColor{id,name}, type, birthdate, Breed { id, name },
  PetRecord ${GetPetRecordPayload}
}`;
// TODO: convert to fragment
export const CreateNewPet = gql`
mutation petAdd($name: String!, $type: PettypeEnumType!, $gender: String!, $breed: String!, $birthdate: Date!) {
  petAdd(Pet:{
    name: $name,
    type: $type,
    gender: $gender,
    BreedId: $breed,
    birthdate: $birthdate
  }) ${GetPetPayload}
}`;

export const GetUserProfile = gql`
  query getProfile {
    getProfile {
      id
      name
      username
      postcode
      addresses
      profile_pic
      preferences
      payment
      postcode
      email
      AppUserCredit {
        id
        amount
      }
    }
  }
`;

export const StartOrder = gql`
  mutation startOrder($items: [SequelizeJSON], $vouchers: [SequelizeJSON], $appointments: [SequelizeJSON], $subscriptions: [SequelizeJSON], $delivery: [SequelizeJSON]) {
    startOrder(StartOrderTypeInput: { items: $items, vouchers: $vouchers, appointments: $appointments, subscriptions: $subscriptions, delivery: $delivery }) {
      id
      Products {
        name
        id
      }
    }
  }
`;

export const StartMultiOrder = gql`
  mutation startMultiOrder($startMultiOrderInput: StartMultiOrderInput) {
    startMultiOrder(StartMultiOrderInput: $startMultiOrderInput) {
      id
    }
  }
`;

export const StartRecurringAppointmentOrder = gql`
  mutation startRecurringAppointmentOrder(
    $weeks_count: Int!
    $start_date: String!
    $day_of_week: [Int]!
    $PetRecordId: [String]!
    $ProductId: String!
    $customizations: SequelizeJSON
    $submitFormsAnswersInput: SubmitFormsAnswersInput
  ) {
    startRecurringAppointmentOrder(
      StartRecurringAppointmentOrderInput: {
        weeks_count: $weeks_count
        start_date: $start_date
        day_of_week: $day_of_week
        PetRecordId: $PetRecordId
        customizations: $customizations
        ProductId: $ProductId
        submitFormsAnswersInput: $submitFormsAnswersInput
      }
    ) {
      id
      Products {
        name
        id
      }
    }
  }
`;

export const GetPreOrderSummary = gql`
  query getPreOrderSummary($items: [SequelizeJSON], $vouchers: [SequelizeJSON], $appointments: [SequelizeJSON], $subscriptions: [SequelizeJSON], $delivery: [SequelizeJSON]) {
    getPreOrderSummary(GetPreOrderSummaryInput: { items: $items, vouchers: $vouchers, appointments: $appointments, subscriptions: $subscriptions, delivery: $delivery }) {
      discount
      appliedVouchers {
        id
        Discount {
          id
          rules
        }
      }
      finalPrices {
        finalPriceWithDeliveryAndCredits
      }
      isAlreadyBooked
      isAlreadySubscribed
      total
      productsWithTotals {
        productTotals {
          priceSummary
        }
      }
    }
  }
`;

export const GetMultiOrderPreOrderSummary = gql`
  query getMultiOrderPreOrderSummary($getMultiOrderPreOrderSummaryInput: GetMultiOrderPreOrderSummaryInput) {
    getMultiOrderPreOrderSummary(GetMultiOrderPreOrderSummaryInput: $getMultiOrderPreOrderSummaryInput) {
      summariesTotal {
        discount
        finalPrices {
          finalPriceWithDeliveryAndCredits
        }
        total
      }
      summaries {
        discount
        isAlreadyBooked
        finalPrices {
          finalPriceWithDeliveryAndCredits
        }
        appliedVouchers {
          id
          Discount {
            id
            rules
          }
        }
        total
        productsWithTotals {
          productTotals {
            priceSummary
          }
        }
      }
    }
  }
`;

export const GetRecurringAppointmentsPreOrderSummary = gql`
  query getRecurringAppointmentsPreOrderSummary($weeks_count: Int!, $start_date: String!, $day_of_week: [Int]!, $PetRecordId: [String]!, $ProductId: String!, $customizations: SequelizeJSON) {
    getRecurringAppointmentsPreOrderSummary(
      GetRecurringAppointmentsPreOrderSummaryInput: {
        weeks_count: $weeks_count
        start_date: $start_date
        day_of_week: $day_of_week
        PetRecordId: $PetRecordId
        ProductId: $ProductId
        customizations: $customizations
      }
    ) {
      summariesTotal {
        discount
        finalPrices {
          finalPriceWithDeliveryAndCredits
        }
        total
      }
      summaries {
        discount
        isAlreadyBooked
        finalPrices {
          finalPriceWithDeliveryAndCredits
        }
        appliedVouchers {
          id
          Discount {
            id
            rules
          }
        }
        productsWithTotals {
          productTotals {
            priceSummary
          }
        }
        total
      }
      timestamps
    }
  }
`;

export const GetAllUserAppointments = gql`
  query getAllUserAppointments {
    getAllUserAppointments {
      id
      timestamp
      status
      PetRecord {
        id
        Pet {
          id
          profile_pic
        }
      }
      OrderItem {
        id
        Order {
          id
          total
          credit_used
          status
          number
          AppUser {
            id
          }
          Products {
            id
            name
            Branch {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const GetAllUserOrderSubscriptions = gql`
  query getAllUserOrderSubscriptions {
    getAllUserOrderSubscriptions {
      id
      status
      OrderSubscriptionRepeats {
        id
        OrderItem {
          id
          item
        }
      }
    }
  }
`;

export const GetAppUserOrdersPaymentActionRequired = gql`
  query getAppUserOrdersPaymentActionRequired {
    getAppUserOrdersPaymentActionRequired {
      orderId
      userId
      paymentIntentClientSecret
      setupIntentClientSecret
      paymentIntentId
      paymentMethodId
    }
  }
`;

export const GetVoucherByCode = gql`
  query getVoucherByCode($code: String!, $BranchId: String!, $ProductId: [String]!, $itemsTotal: Float, $petsCount: Int) {
    getVoucherByCode(GetVoucherByCodeInput: { code: $code, BranchId: $BranchId, itemsTotal: $itemsTotal, petsCount: $petsCount, ProductId: $ProductId }) {
      id
      code
      Discount {
        id
        rules
      }
    }
  }
`;

export const UpdateUserInfo = gql`
  mutation updateAppUser(
    $postcode: String
    $name: String
    $username: String
    $addresses: [SequelizeJSON]
    $profile_pic: String
    $preferences: SequelizeJSON
    $payment: SequelizeJSON
    $locale: SequelizeJSON
  ) {
    updateAppUser(AppUser: { postcode: $postcode, name: $name, username: $username, addresses: $addresses, profile_pic: $profile_pic, preferences: $preferences, payment: $payment, locale: $locale }) {
      id
      name
      username
      postcode
      addresses
      profile_pic
      preferences
      payment
      postcode
      email
    }
  }
`;

export const GetAllBreeds = gql`
  query breedGet {
    breedGet(order: "name") {
      type
      name
      id
    }
  }
`;

export const GetBranchById = gql`
  query getBranchById($id: String!) {
    getBranchById(GetBranchByIdInput: { id: $id }) {
      id
      name
      photos
      colors
      booking_slots
      terms_and_conditions_url
      privacy_policy_url
      analytics
      address
      additional_info
      branchCurrency {
        currency
        symbol
      }
      BranchTargeting {
        id
        visibility
        status
        pet_type
      }
      visibleProducts {
        id
        name
        description
        short_description
        original_price
        additional_info
        allows_repeated_orders
        slugs
        show_customizations_price
        allows_bulk_orders
        max_bulk_orders
        payment
        price
        charge_type
        unit
        status
        type
        BranchId
        Branch {
          id
          name
        }
        use_pets_count
        Flags {
          id
          name
        }
        cta_text
        stock
        duration
        sort_index
        slots_start_date
        slots_recurrence
        booking_type
        slots
        customizations
        sessions
        enable_customizations_quantity_factor
        SubscriptionProducts {
          id
        }
        pet_rules
      }
    }
  }
`;

export const GetProviderById = gql`
  query getProviderById($id: String!) {
    getProviderById(GetProviderByIdInput: { id: $id }) {
      id
      name
      Branches {
        id
        name
        photos
        colors
        booking_slots
        terms_and_conditions_url
        privacy_policy_url
        additional_info
        analytics
        visibleProducts {
          id
          name
          description
          short_description
          original_price
          additional_info
          allows_repeated_orders
          show_customizations_price
          slugs
          allows_bulk_orders
          max_bulk_orders
          payment
          price
          charge_type
          unit
          status
          type
          BranchId
          Branch {
            id
            name
          }
          use_pets_count
          Flags {
            id
            name
          }
          cta_text
          stock
          duration
          sort_index
          slots_start_date
          slots_recurrence
          booking_type
          slots
          customizations
          sessions
          enable_customizations_quantity_factor
          SubscriptionProducts {
            id
          }
          pet_rules
        }
      }
    }
  }
`;

export const EditPetRecord = gql`
  mutation petRecordEdit(
    $id: String
    $measurements: SequelizeJSON
    $shape: PetRecordshapeEnumType
    $neutred: Boolean
    $allergies: SequelizeJSON
    $chronic_conditions: SequelizeJSON
    $disabilities: SequelizeJSON
    $microchip_number: String
    $microchip_provider: String
    $passport_number: String
  ) {
    petRecordEdit(
      PetRecord: {
        id: $id
        shape: $shape
        neutred: $neutred
        measurements: $measurements
        allergies: $allergies
        chronic_conditions: $chronic_conditions
        disabilities: $disabilities
        microchip_number: $microchip_number
        microchip_provider: $microchip_provider
        passport_number: $passport_number
      }
    ) {
      id
      shape
      neutred
      measurements
      allergies
      chronic_conditions
      disabilities
      microchip_number
      microchip_provider
      passport_number
    }
  }
`;

export const GetCardAuthentication = gql`
  query getCardAuthentication {
    getCardAuthentication {
      clientSecret
      status
      ephemeralKey
      customerId
    }
  }
`;

export const HandleOrderPaymentAfterAction = gql`
  mutation handleOrderPaymentAfterAction($orderId: String!, $paymentIntentId: String, $failure: String, $paymentMethodId: String) {
    handleOrderPaymentAfterAction(HandleOrderPaymentAfterActionInput: { orderId: $orderId, paymentIntentId: $paymentIntentId, failure: $failure, paymentMethodId: $paymentMethodId }) {
      id
    }
  }
`;

export const GetRequiredForms = gql`
  query getRequiredFormsForOrder($ProductId: [String]!, $PetRecordId: [String]!) {
    getRequiredFormsForOrder(GetRequiredFormsForOrderInput: { ProductId: $ProductId, PetRecordId: $PetRecordId }) {
      id
      name
    }
  }
`;

export const GetReducedBranchForms = gql`
  query getForms($branchId: String) {
    getForms(GetFormsInput: { branchId: $branchId }) {
      id
      name
    }
  }
`;

export const GetForms = gql`
  ${BRANCH_FORM_FRAGMENT}
  query getForms($branchId: String, $id: [String]) {
    getForms(GetFormsInput: { id: $id, branchId: $branchId }) {
      ...BranchFormFragment
    }
  }
`;

export const SubmitFormsAnswers = gql`
  mutation submitFormsAnswers($submitFormsAnswersInput: SubmitFormsAnswersInput) {
    submitFormsAnswers(SubmitFormsAnswersInput: $submitFormsAnswersInput) {
      id
    }
  }
`;

export const GetAllCountries = gql`
  query countryGet {
    countryGet(limit: 1000) {
      id
      name
    }
  }
`;

export const GetSlots = gql`
  query getSlots($branchId: String!, $productId: String, $customizations: [SequelizeJSON], $slots_length: Int) {
    getSlots(GetSlotsInput: { branchId: $branchId, productId: $productId, customizations: $customizations, slots_length: $slots_length }) {
      slots
    }
  }
`;
