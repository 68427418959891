export default {
  white: '#FFFFFF',
  black: '#000000',
  textPrimary: '#000000',
  primaryGray: '#c0c0c0',
  secondaryGray: '#c0c0c0',
  staticWhite: '#ffffff',
  staticBlack: '#000000',
  darkGray: '#404040',
  error: '#c60000',
  defaultGrayBackground: '#F4F4F4',
  orange: '#eb5e00',
  divider: '#EDEDED'
} as const;

export const InvertedColors = {
  white: '#000000',
  black: '#FFFFFF',
  textPrimary: '#FFFFFF',
  primaryGray: '#C8C8C8',
  secondaryGray: '#C8C8C8',
  staticWhite: '#ffffff',
  staticBlack: '#000000',
  darkGray: '#F0F0F0',
  error: '#c60000',
  defaultGrayBackground: '#F4F4F4',
  orange: '#eb5e00',
  divider: '#EDEDED'
};

export const isColorDark = (color: string) => {
  const hex = color.replace('#', '');
  const c_r = parseInt(hex.substr(0, 2), 16);
  const c_g = parseInt(hex.substr(2, 2), 16);
  const c_b = parseInt(hex.substr(4, 2), 16);
  const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;
  return brightness < 155;
};
